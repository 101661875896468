<template>
	<b-modal no-close-on-backdrop ref="modal" size="xl" hide-footer @hidden="clearModal" @show="init_component">
		<template v-slot:modal-title>
			{{ $t("invoice.devis.add_devis") }}
		</template>

		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>
			<div class="col-12" v-if="!isAddArticle">
				<div class="mb-2">
					<label class="mb-1" for="search_article">{{ $t('invoice.article_a_facturer') }}</label>

					<SearchArticles 
						:entity_id.sync="author_id"
						:preselected.sync="details.articles"
						:articles_selected.sync="on_article_selected"
					></SearchArticles>
					
				</div>
			</div>

			<div class="col-6" v-if="!isAddArticle">
				<div class="mb-2">
					<label class="mb-1" for="articles_ht">{{ $t('compta.form.tarif_ht') }} *</label>
					<PriceInput v-model="details.quotationdetails_htunit_before_discount" />
				</div>
			</div>

			<div class="col">
				<div class="mb-2">
					<label class="mb-1" for="articles_ht">{{ $t('compta.form.qte') }} *</label>
					<input type="text" class="form-control" v-model="details.quotationdetails_qte" required>
				</div>
			</div>
			
			<div class="col-12" v-if="!isAddArticle">
				<hr>
				<button class="btn btn-secondary btn-block mb-2 bnt-block" @click.prevent="addArticle"><font-awesome-icon :icon="['far', 'layer-plus']" /> {{ $t('invoice.creer_article') }}</button>
			</div>

			<div class="col-12" v-if="isAddArticle">
				<div class="row">
					<div class="col-12">
					    <div class="mb-2">
					        <label for="articles_articlestype" class="col-form-label mb-1">{{ $t("compta.form.articles_type") }} *</label>
					         <e-select
					            v-model="articles.articles_articlestype"
					            id="articles_articlestype"
					            track-by="articlestype_id"
					            label="articlestype_label"
					            :options="articles_type"
					            :searchable="true"
					            :show-labels="false"
					            :allow-empty="false"
					            :class="{ 'is-invalid': error && error.indexOf('Erreur articles type') > -1 }"
					        >
					    	</e-select>
					    </div>
					</div>
				</div>
				<template v-if="articles.articles_articlestype != null">
					<div class="row">
						<div class="col-6">
							<div class="mb-2">
								<label class="mb-1" for="articles_label">{{ $t('compta.form.label_article') }} *</label>
								<input type="text" class="form-control" v-model="articles.articles_label" required>
							</div>
						</div>
						<div class="col-6">
							<div class="mb-2">
								<label class="mb-1" for="articles_code">{{ $t('compta.form.code_article') }} *</label>
								<input type="text" class="form-control" v-model="articles.articles_code" required>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-6">
							<div class="mb-2">
								<label class="mb-1" for="articles_ht">{{ $t('compta.form.tarif_ht') }} *</label>
								<input type="text" class="form-control" v-model="articles.articles_ht" required>
							</div>
						</div>
						<div class="col-6">
							<div class="mb-2">
								<label class="mb-1" for="articles_vat">{{ $t('compta.form.vat') }} *</label>
								<e-select
									v-model="articles.articles_vat"
									id="search_vat"
									track-by="value"
									label="name"
									:options="vat"
									:searchable="true"
									:allow-empty="false"
									:show-labels="false"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12 col-sm-6">
							<div class="mb-2">
								<label for="articles_accountingaccount" class="col-form-label mb-1">{{ $t("compta.form.compte") }} *</label>
								<e-select
								    v-model="articles.articles_accountingaccount"
								    id="articles_accountingaccountfdvex"
								    track-by="id"
								    label="name"
								    :options="comptes_comptables"
								    :searchable="true"
								    :show-labels="false"
								    :allow-empty="false"
								    :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable') > -1 }"
								>
								    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>

						<div class="col-12 col-sm-6">
						    <div class="mb-2">
						        <label for="articles_vataccount" class="col-form-label mb-1">{{ $t("compta.form.vat_compte_comptable") }} *</label>
						        <e-select
						            v-model="articles.articles_vataccount"
						            id="articles_vataccount"
						            track-by="id"
						            label="name"
						            :options="comptes_comptables_vat"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						        
						    </div>
						</div>

						<div class="col-12 col-sm-6">
						    <div class="mb-2">
						        <label for="articles_accountingaccountfdvxp" class="col-form-label mb-1">{{ $t("compta.form.articles_accountingaccountfdvxp") }} *</label>
						        <e-select
						            v-model="articles.articles_accountingaccountfdvxp"
						            id="articles_accountingaccountfdvxp"
						            track-by="id"
						            label="name"
						            :options="comptes_comptables"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable fdvxp') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						    </div>
						</div>


						<div class="col-12 col-sm-6">
						    <div class="mb-2">
						        <label for="articles_vataccountfdvxp" class="col-form-label mb-1">{{ $t("compta.form.articles_vataccountfdvxp") }} *</label>
						        <e-select
						            v-model="articles.articles_vataccountfdvxp"
						            id="articles_vataccountfdvxp"
						            track-by="id"
						            label="name"
						            :options="comptes_comptables_vat"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable fdvxp') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						    </div>
						</div>


						<div class="col-12 col-sm-6">
						    <div class="mb-2">
						        <label for="articles_accountingaccountfdvex" class="col-form-label mb-1">{{ $t("compta.form.articles_accountingaccountfdvex") }} *</label>
						        <e-select
						            v-model="articles.articles_accountingaccountfdvex"
						            id="articles_accountingaccountfdvex"
						            track-by="id"
						            label="name"
						            :options="comptes_comptables"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable fdvex') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						    </div>
						</div>

						<div class="col-12 col-sm-6">
						    <div class="mb-2">
						        <label for="articles_vataccountfdvex" class="col-form-label mb-1">{{ $t("compta.form.articles_vataccountfdvex") }} *</label>
						        <e-select
						            v-model="articles.articles_vataccountfdvex"
						            id="articles_vataccountfdvex"
						            track-by="id"
						            label="name"
						            :options="comptes_comptables_vat"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable fdvex') > -1 }"
						        >
						            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						        </e-select>
						    </div>
						</div>
					</div>
				</template>
			</div>

			<div class="col-12">
				<b-form-checkbox id="discount_checkbox" v-model="has_discount">
					<label class="mb-2" for="discount_checkbox">{{ $t("compta.form.appliquer_remise") }}</label>
				</b-form-checkbox>
			</div>
			<div class="col-12" v-if="has_discount">
				<div class="row">
					<div class="col-6">
						<label class="mb-1" for="discount_type">{{ $t('compta.form.discount_type') }}</label>
						<e-select
							v-model="details.quotationdetails_discount_type"
							id="discount_type"
							track-by="invoicediscounttype_id"
							label="invoicediscounttype_label"
							:options="discount_type_formatted"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicediscounttype_label) }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
					<div class="col-6">
						<div class="mb-2">
							<label class="mb-1" for="discount_value">{{ $t('compta.form.discount_value') }}</label>
							<input type="text" id="discount_value" class="form-control" v-model="details.quotationdetails_discount_value">
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="mb-2">
					<label class="mb-1" for="vat_code">{{ $t('compta.form.invoicedetails_codevat') }}</label>
					<e-select
						v-model="vat_export"
						id="vat_code"
						track-by="id"
						label="label"
						:options="vat_filtred"
						:searchable="true"
						:allow-empty="true"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>

			<div class="col-12">
				<div class="mb-2">
					<label class="mb-1" for="commentaire">{{ $t('invoice.commentaire') }}</label>
					<!-- <textarea id="commentaire" class="form-control" rows="3" v-model="details.quotationdetails_description"></textarea> -->
					<e-editor-froala
						v-if="show_commentaire"
						identifier="quotationdetails_description"
						:placeholder="getTrad('invoice.commentaire')"
						:content="details.quotationdetails_description"
						@change="updateEditorComment"
						:moreText="[ 'bold', 'italic', 'strikeThrough', 'underline', 'undo', 'redo', 'formatUL', 'formatOL', 'clearFormatting' ]"
					></e-editor-froala>
				</div>
			</div>

			<div class="col-12 text-center">
				<b-button variant="primary" @click="validForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t('global.ajouter') }}</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script type="text/javascript">
    import Quotation from "@/mixins/Quotation.js"
    import Invoice from "@/mixins/Invoice.js"
    import Article from "@/mixins/Article.js"
    import Accounting from "@/mixins/Accounting.js"
    import { EventBus } from "EventBus";

	export default {
		name: 'ModalAddDevisDetails',
		mixins: [Quotation, Invoice, Article, Accounting],
		props: ['quotation_id', 'author_id', 'tiers_id'],
		data () {
			return {
				details: {
					quotationdetails_description: null,
					quotationdetails_label: null,
					quotationdetails_ht: null,
					quotationdetails_htunit: null,
					quotationdetails_qte: 1,
					quotationdetails_vat: 1,
					quotationdetails_articles: null,
					quotationdetails_exportvat: null,
					quotationdetails_htunit_before_discount: null,
					quotationdetails_ht_before_discount: null,
					quotationdetails_discount_value: null,
					quotationdetails_discount_type: null,
					quotationdetails_ht_initial: null,
					discount_type: null,
				},
				processing: false,
				isAddArticle: false,
				on_article_selected : null,
				has_discount: false,
				discount_type: [],
				vat_filtred: [],
				vat_export: null,
				articles_type: [],
				error: [],
				articles: {
					articles_label: null,
					articles_code: null,
					articles_ht: null,
					articles_vat: null,
					articles_accountingaccount: null,
					articles_accountingaccountfdvex: null,
					articles_accountingaccountfdvxp: null,
					articles_vataccount: null,
					articles_vataccountfdvex: null,
					articles_vataccountfdvxp: null,
					articles_articlestype: null,
				},
				vat: [],
				comptes_comptables: [],
                comptes_comptables_vat: [],
				cache: {
					comptes_comptables: {},
					articles: {}
				},
				form_message: '',
				articles_id: null,
				show_commentaire: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.discount_type = await this.loadDiscountType()

				this.vat = []
				const vat = await this.getVat()
				for (let i = 0; i < vat.length; i++) {
					this.vat.push({
						id: vat[i].vat_id,
						name: vat[i].vat_label,
						value: vat[i].vat_value,
					})
				}


				if(Object.keys(this.cache.comptes_comptables).length == 0) {
					this.cache.comptes_comptables = {}
				}
				if(this.cache.comptes_comptables[this.author_id] == null) {
					this.loadAccountingAccountsTiers(this.author_id).then(comptes_comptables => {
						this.display_compte_comptable(comptes_comptables)
						this.cache.comptes_comptables[this.author_id] = comptes_comptables
					})
				}
				else {
					const comptes_comptables = this.cache.comptes_comptables[this.author_id]
					this.display_compte_comptable(comptes_comptables)
				}

				this.loadVatCode()
			},

			openModal(details_id = null) {
                this.$refs.modal.show()
                if(details_id) {
                	this.loadDetailsById(details_id)
                } else {
	                this.show_commentaire = true
	            }
			},

			async validForm() {
				if(!this.processing) {
					this.processing = true
					this.error = []
					let error_values = []

					if(this.isAddArticle) {
						if(this.articles.articles_vat === null) {
							this.error.push("Erreur vat")
						}

						if(this.articles.articles_accountingaccount === null) {
							this.error.push("Erreur accounting account")
						}

						if(this.articles.articles_accountingaccountfdvex === null) {
							this.error.push("Erreur accounting account fdvex")
						}

						if(this.articles.articles_accountingaccountfdvxp === null) {
							this.error.push("Erreur accounting account fdvxp")
						}

						if(this.articles.articles_vataccount === null) {
							this.error.push("Erreur vat accounting account")
						}

						if(this.articles.articles_vataccountfdvex === null) {
							this.error.push("Erreur vat accounting account fdvex")
						}

						if(this.articles.articles_vataccountfdvxp === null){
							this.error.push("Erreur vat accounting account fdvxp")
						}

						if(!this.articles.articles_label) {
							this.error.push("Erreur accounting label")
						}

						if(!this.articles.articles_code) {
							this.error.push("Erreur accounting code")
						}

						if(!this.articles.articles_ht) {
							this.error.push("Erreur accounting ht")
						}
					}
					else if(this.details.quotationdetails_articles == null) {
						this.error.push("Erreur no article selected")
					}
					
					if(this.details.quotationdetails_qte.toString().indexOf(",") > -1) {
						this.details.quotationdetails_qte = this.details.quotationdetails_qte.toString().substring(0, this.details.quotationdetails_qte.toString().indexOf(",") + 3)
					}
					else if(this.details.quotationdetails_qte.toString().indexOf(".") > -1) {
						this.details.quotationdetails_qte = this.details.quotationdetails_qte.toString().substring(0, this.details.quotationdetails_qte.toString().indexOf(".") + 3)
					}

					if(!this.isAddArticle && !this.details.quotationdetails_htunit_before_discount) {
						this.error.push("ht unit")
					}

					if(this.error.length === 0 && error_values.length === 0) {
						this.form_message = ""
						let qte = this.details.quotationdetails_qte
						let htunit = this.details.quotationdetails_htunit_before_discount
						let discount_value = (this.has_discount) ? this.details.quotationdetails_discount_value : null
						let discount_type = (this.has_discount) ? this.details.quotationdetails_discount_type : null
						let vat = this.vat_export.id
						let label = this.details.quotationdetails_label
						let description = this.details.quotationdetails_description
						let vat_code = this.details.quotationdetails_codevat
						let export_vat = this.details.quotationdetails_exportvat
						let articles_id = null
						if(this.details.quotationdetails_articles != null) {
							articles_id = this.details.quotationdetails_articles.articles_id
						}


						if(this.isAddArticle) {
							vat = this.vat.find(vat => { return vat.id == this.articles.articles_vat.id })
							const tva = parseFloat(vat.value)
							const ht = parseFloat(this.articles.articles_ht)
							let ttc = (vat.value * ht) + ht

							let result = await this.addArticles(
								this.articles.articles_accountingaccount.id,
								this.articles.articles_vat.id,
								this.articles.articles_label,
								this.articles.articles_code,
								this.articles.articles_ht,
								ttc,
								this.articles.articles_articlestype.articlestype_id,
								this.articles.articles_accountingaccountfdvex.id,
								this.articles.articles_accountingaccountfdvxp.id,
								this.articles.articles_vataccount.id,
								this.articles.articles_vataccountfdvex.id,
								this.articles.articles_vataccountfdvxp.id
							);
							
							htunit = this.articles.articles_ht
							this.details.quotationdetails_ht_initial = htunit
							vat = this.articles.articles_vat.id
							label = this.articles.articles_label
							
							if(result) {
								articles_id = result.retour.articles_id
							}
							else {
								this.form_message = "error.LEP"

								this.$emit('update:processing',false)
								return false; 
							}
						}

						let paramsToSend = {
							quotation_id: this.quotation_id,
							author_id: this.author_id,
							tiers_id: this.tiers_id,
							htunit: htunit,
							qte: qte,
							vat: vat,
							label: label,
							description: description,
							articles_id: articles_id,
							vat_code: vat_code,
							export_vat: export_vat,
							discount_value: (this.has_discount) ? discount_value : null,
							discount_type: (this.has_discount) ? discount_type : null,
						}

						let result_quotation_details = null

						if(this.details.quotationdetails_id) {
							paramsToSend.ht_initial = this.details.quotationdetails_ht_initial
							result_quotation_details = await this.updateQuotationDetails(this.details.quotationdetails_id, paramsToSend);
						}
						else {
							paramsToSend.ht_initial = this.details.quotationdetails_ht_initial * 100
							result_quotation_details = await this.createDetailsQuotation(paramsToSend);
						}

						
						if(result_quotation_details) {
							EventBus.$emit('EditDevis::ReloadDetails', this._uid)
                			this.$refs.modal.hide()
							this.details = {}
							this.isAddArticle = false
							this.has_discount = false
							this.vat_export = null
							this.articles = {
								articles_label: null,
								articles_code: null,
								articles_ht: null,
								articles_vat: null,
								articles_accountingaccount: null,
								articles_accountingaccountfdvex: null,
								articles_accountingaccountfdvxp: null,
								articles_vataccount: null,
								articles_vataccountfdvex: null,
								articles_vataccountfdvxp: null,
								articles_articlestype: null,
							}
							this.articles_id = null
							this.processing = false
						}
						else {
							this.failureToast();
						}
					}
					else if(error_values.length > 0) {
						this.form_message = "formulaire.erreur_champs_mal_remplis"
						this.failureToast('formulaire.erreur_champs_mal_remplis')
					}
					else {
						this.form_message = "formulaire.erreur_champs_non_remplis"
						this.failureToast('formulaire.erreur_champs_non_remplis')
					}

					this.processing = false
					return false;
				}
			},

			async addArticle() {
				this.isAddArticle = true
				let articles_type = await this.getArticlesType()
				for (let i = 0; i < articles_type.length; i++) {
					this.articles_type.push({
						articlestype_id: articles_type[i].articlestype_id,
						articlestype_label: this.getTrad(articles_type[i].articlestype_label),
						articlestype_code: articles_type[i].articlestype_code,
					})
				}
			},

			onArticleSelection(value) {
				if(value != undefined) {
					this.articles_id = value
					let article_selected = value
					if(article_selected != undefined) {
						this.details.quotationdetails_vat = article_selected.vat.vat_id

						this.details.quotationdetails_ht_initial = article_selected.articles_ht / 100
						this.details.quotationdetails_htunit_before_discount = article_selected.articles_ht / 100
						this.details.quotationdetails_label = article_selected.articles_label
						this.details.quotationdetails_qte = article_selected.articles_quantite
						this.show_commentaire = false
						this.details.quotationdetails_description = article_selected.articles_commentaire
						this.$nextTick(function () {
							this.show_commentaire = true
						})

						this.fillVatFiltred(article_selected.vat.vat_id, article_selected.articles_type.articlestype_code)
					}
				}
			},

			fillVatFiltred(vat_id, type) {
				const vat_of_article = this.vat.find(vat => vat.id == vat_id)
				const vat_of_zero = this.vat.find(vat => vat.id == 1)

				this.vat_filtred = []

				if(type == 'produit') {
					vat_of_article.label = vat_of_article.name + " " + this.getTrad("compta.form.produit_non_exporte")
					this.vat_filtred.push(vat_of_article)
					this.vat_export = vat_of_article

					if(this.details.quotationdetails_codevat == "FDVXP" || this.details.quotationdetails_codevat == "FDVEX") {
						vat_of_zero.label = vat_of_zero.name + " " + this.getTrad("compta.form.produit_exporte")
						this.vat_filtred.push(vat_of_zero)
					}
				}

				
				if(type == 'service') {
					vat_of_article.label = vat_of_article.name + " " + this.getTrad("compta.form.service_non_exporte")
					this.vat_filtred.push(vat_of_article)
					

					if(this.details.quotationdetails_codevat == "FDVXP" || this.details.quotationdetails_codevat == "FDVEX") 	{
						vat_of_zero.label = vat_of_zero.name + " " + this.getTrad("compta.form.service_exporte")
						this.vat_filtred.push(vat_of_zero)
						this.vat_export = vat_of_zero
					}
					else {
						this.vat_export = vat_of_article
					}
				}
			},

			async loadVatCode(){
				this.details.quotationdetails_codevat = await this.getVatCode(this.author_id, this.tiers_id)
			},

			async calcul_htunit_before_discount() {
				if(this.details.quotationdetails_ht_initial == null || this.details.quotationdetails_ht_initial == ''){
					return false
				}

				const ht_initial = this.details.quotationdetails_ht_initial.toString().replace(',', '.').replace(/\s/g, '')

				this.details.quotationdetails_htunit_before_discount =  await this.priceFormat(ht_initial)
			},

			display_compte_comptable(comptes_comptables){
				this.comptes_comptables_vat = []
				this.comptes_comptables = []
				for (let i = 0; i < comptes_comptables.length; i++) {
					if(comptes_comptables[i].accountingaccount_vat == null) {
						this.comptes_comptables.push({
							id: comptes_comptables[i].accountingaccount_id,
							name: comptes_comptables[i].accountingaccount_number + " ( " + comptes_comptables[i].accountingaccount_label + " )",
						})
					}
					else {
						this.comptes_comptables_vat.push({
						    name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
						    id: comptes_comptables[i].accountingaccount_id })
					}
				}
			},

			async loadDetailsById(details_id) {
				let details = await this.getQuotationDetailById(details_id)

				if(details.quotationdetails_ht_initial == null || details.quotationdetails_ht_initial == "0.00") {
					details.quotationdetails_ht_initial = details.quotationdetails_htunit_before_discount
				}

				details.articles.articles_articlestype = {articlestype_code: details.articles.articles_type.articlestype_code}
				details.articles.articles_vat = {
					id: details.articles.vat.vat_id,
					label: details.articles.vat.vat_label
				}
				details.quotationdetails_articles = details.articles
				this.onArticleSelection(details.articles)
				this.articles = details.articles
				this.has_discount = details.discount_type ? true : false
				if(this.has_discount) {
					details.quotationdetails_discount_type = this.discount_type_formatted.find(discount => discount.invoicediscounttype_id == details.quotationdetails_discount_type)
				}

				this.details = details
				this.show_commentaire = true
			},

			clearModal() {
				this.details = {
					quotationdetails_description: null,
					quotationdetails_label: null,
					quotationdetails_ht: null,
					quotationdetails_htunit: null,
					quotationdetails_qte: 1,
					quotationdetails_vat: 1,
					quotationdetails_articles: null,
					quotationdetails_exportvat: null,
					quotationdetails_htunit_before_discount: null,
					quotationdetails_ht_before_discount: null,
					quotationdetails_discount_value: null,
					quotationdetails_discount_type: null,
					quotationdetails_ht_initial: null,
					discount_type: null
				}
				this.isAddArticle = false
				this.on_article_selected  = null
				this.has_discount = false
				this.vat_filtred = []
				this.vat_export = null
				this.articles_type = []
				this.error = []
				this.articles = {
					articles_label: null,
					articles_code: null,
					articles_ht: null,
					articles_vat: null,
					articles_accountingaccount: null,
					articles_accountingaccountfdvex: null,
					articles_accountingaccountfdvxp: null,
					articles_vataccount: null,
					articles_vataccountfdvex: null,
					articles_vataccountfdvxp: null,
					articles_articlestype: null,
				}
				this.comptes_comptables = []
                this.comptes_comptables_vat = []
				this.cache = {
					comptes_comptables: {},
					articles: {}
				}
				this.form_message = ''
				this.articles_id = null
				this.show_commentaire = false
			},

			updateEditorComment(val){
				this.details.quotationdetails_description = val.val
			}

		},
		
		computed: {
			discount_type_formatted(){
				let tab = []

				for(let i = 0; i < this.discount_type.length; i++) {
					let current = this.discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label)
					tab.push(current)
				}

				return tab
			}
		},

		watch:{
			on_article_selected(val) {
				this.details.quotationdetails_articles = val
				this.onArticleSelection(val)
			},
			'details.quotationdetails_ht_initial'(value) {
				this.calcul_htunit_before_discount()
			},
			'articles.articles_label'() {
				if(this.articles && this.articles.articles_label) {
					this.articles.articles_code = this.accentsTidy(this.articles.articles_label).replace(/ /g, "_")
				}
			},
			'articles.articles_vat'(value) {
				if(this.articles && value) {
					this.fillVatFiltred(value.id, this.articles.articles_articlestype.articlestype_code)
				}
			},
			'vat_export'(val) {
				if((this.details.quotationdetails_codevat == "FDVXP" || this.details.quotationdetails_codevat == "FDVEX") && val.id == 1) {
					this.details.quotationdetails_exportvat = 1
				}
				else {
					this.details.quotationdetails_exportvat = 0
				}
			},
		},

		components: {
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput'),
			SearchArticles: () => import('@/components/Utils/SearchArticles'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		}
	}

</script>